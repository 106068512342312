var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "vx-row w-full" }, [
          _c("div", { staticClass: "vx-col" }, [
            _c("b", [_vm._v(_vm._s(_vm.$t("certified.name")))]),
          ]),
        ]),
        _c("div", { staticClass: "vx-row w-full" }, [
          _c("div", { staticClass: "vx-col" }, [
            _vm._v(_vm._s(_vm.model.name)),
          ]),
        ]),
        _c("div", { staticClass: "vx-row w-full" }, [
          _c(
            "div",
            { staticClass: "vx-col" },
            [
              _c("e-charts", {
                ref: "graph",
                attrs: { autoresize: "", options: _vm.dataChart },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "w-full flex gap-2 pt-2 justify-end" },
          [
            _c(
              "vs-button",
              {
                attrs: { type: "border", icon: "undo" },
                on: { click: _vm.goBack },
              },
              [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }